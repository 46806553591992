import React, { useEffect, useState } from "react"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Table,
  Button,
  Input,
} from "reactstrap"

//redux
import Piet from "../../Charts/piechart"
import * as moment from "moment"
import { withTranslation } from "react-i18next"
import "react-datepicker/dist/react-datepicker.css"
import {
  getIncidentsFilters,
  getReportIncidentsTypeByEntity,
} from "../../../helpers/backend_helper"
import ReactSelect from "react-select"
import ReactDatePicker from "react-datepicker"
import LineChartDate from "../../Charts/linechartDate"
import LineChartTime from "../../Charts/linechartTime"

const IncidentsByEntity = (props) => {
  const t = props?.t
  const masterItemName = t("Report by Filter")
  const masterItemNamePlural = t("Report by Filter")
  const [loading, setLoading] = useState(false)
  const [loadingReport, setLoadingReport] = useState(false)
  const [report, setReport] = useState([])
  const [entities, setEntities] = useState([])
  const [incidentTypes, setIncidentTypes] = useState([])
  const [incidentSubtypes, setIncidentSubtypes] = useState([])
  const [incidentLocations, setIncidentLocations] = useState([])
  const [selectedEntity, setSelectedEntity] = useState([])
  const [selectedType, setSelectedType] = useState([])
  const [selectedSubtype, setSelectedSubtype] = useState([])
  const [selectedLocation, setSelectedLocation] = useState([])
  const [selectedRadio, setSelectedRadio] = useState(1)
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().endOf("day").toDate(),
  })
  const [touched, setTouched] = useState(false)

  const filterPassedTime = (time) => {
    const startDate = selectedPeriod.start
    const selectedDate = new Date(time)

    return selectedDate.getTime() > startDate.getTime()
  }

  //meta title
  document.title = masterItemName + " | Tellit"

  /*
  const fetchEntities = async () => {
    const response = await getEntitiesSelect()
    setEntities(
      response.map((e) => {
        return { value: e.id, label: e.name }
      })
    )
    if ((response?.length ?? 0) > 0)
      setSelectedEntity({ value: response[0].id, label: response[0].name })
    setLoading(false)
  }

  const fetchIncidentsTypes = async () => {
    console.log("selectedEntity", selectedEntity)
    const response = await getEntityIncidentTypesWithSubtypesSelect(
      selectedEntity.value
    )

    return response
  }
  */

  const fetchIncidentsFilters = async () => {
    console.log("selectedEntity", selectedEntity)
    const response = await getIncidentsFilters()
    setEntities(response.filters.entities)
    setIncidentTypes(response.filters.types)
    setIncidentSubtypes(response.filters.subtypes)
    
    for(let item of response.filters.locations) {
      if(item.location?.length == 0) item.location = t('<location not specified>')
    }
    setIncidentLocations(response.filters.locations)
      
    return response
  }

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      await fetchIncidentsFilters()
      setLoading(false)
    }

    load()
  }, [])

  /*
  useEffect(() => {
    const load = async () => {
      setLoading(true)
      await fetchEntities()
      setLoading(false)
    }

    load()
  }, [])

  useEffect(() => {
    const load1 = async () => {
      setLoading(true)
      const response = !selectedEntity.incidentTypes
        ? await fetchIncidentsTypes()
        : selectedEntity.incidentTypes
      setIncidentTypes(response)
      selectedEntity.incidentTypes = response
      setLoading(false)
    }

    console.log("selectedEntity", selectedEntity)
    setSelectedType(null)
    if (selectedEntity) load1()
  }, [selectedEntity])

  useEffect(() => {
    setSelectedSubtype(null)
  }, [selectedType])

  useEffect(() => {
    setSelectedLocation(null)
  }, [selectedSubtype])
  */

  const fetchReport = async () => {
    setLoadingReport(true)
    try {
      const response = await getReportIncidentsTypeByEntity({
          periodType: parseInt(selectedRadio) == 1 ? 'date':'time',
          entity: selectedEntity?.length == 0 ? []:selectedEntity.map(e => e.fullnameentity),
          type: selectedType?.length == 0 ? []:selectedType.map(e => e.type),
          subtype: selectedSubtype?.length == 0 ? []:selectedSubtype.map(e => e.subtype),
          location: selectedLocation?.length == 0 ? []:selectedLocation.map(e => e.location == t('<location not specified>') ? '':e.location),
          period: parseInt(selectedRadio) == 1 ? 
                    {start: moment(selectedPeriod.start).format("Y-MM-DD HH:mm") , end: moment(selectedPeriod.end).format("Y-MM-DD HH:mm")} :
                    {start: moment(selectedPeriod.start).format("Y-MM-DD HH:mm") , end: moment(selectedPeriod.end).format("Y-MM-DD HH:mm")}
        })

      for(let item of response.locations.summary) {
        if(item.location?.length == 0) item.location = t('<location not specified>')
      }
      for(let item of response.locations.summaryByPeriod) {
        if(item.location?.length == 0) item.location = t('<location not specified>')
      }

      setReport(response)
    } catch (err) {
      console.log(err)
    }
    setLoadingReport(false)
  }

  function resetFields(){
    setSelectedEntity([])
    setSelectedType([])
    setSelectedSubtype([])
    setSelectedLocation([])
    setSelectedRadio(1)
    setSelectedPeriod({start:null, end:null})
    setTouched(false)
    setReport([])
  }

  // console.log(selectedEntity, selectedPeriod)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t(masterItemName)}
            breadcrumbItem={masterItemNamePlural}
          />
          <Row>
            <Col lg="12">
              <Card style={{ minHeight: "300px" }}>
                <CardBody className="">
                  <Row className="mb-2">
                    <Col md="3">
                      <Label className="form-Label">{t("Entity")}</Label>
                      <ReactSelect
                        name="incident_type"
                        options={entities}
                        value={selectedEntity}
                        onChange={(option) => {
                          setSelectedEntity(option)
                        }}
                        isClearable={true}
                        getOptionValue={(v) => v.fullnameentity}
                        getOptionLabel={(v) => v.fullnameentity}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedEntity==null &&
                        <div className="text-danger">{t('Required')}</div>
                      }
                    </Col>
                    <Col md="3">
                      <Label className="form-Label">{t("Incident Type")}</Label>
                      <ReactSelect
                        name="incident_type"
                        options={incidentTypes ? incidentTypes : []}
                        value={selectedType}
                        onChange={(option) => {
                          setSelectedType(option)
                        }}
                        isClearable={true}
                        getOptionValue={(v) => v.type}
                        getOptionLabel={(v) => v.type}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedType==null &&
                        <div className="text-danger">{t('Required')}</div>
                      }
                    </Col>

                    <Col md="3">
                      <Label className="form-Label">
                        {t("Incident Subtype")}
                      </Label>
                      <ReactSelect
                        name="incident_type"
                        options={incidentSubtypes ? incidentSubtypes : []}
                        value={selectedSubtype}
                        isClearable={true}
                        onChange={(option) => {
                          setSelectedSubtype(option)
                        }}
                        getOptionValue={(v) => v.subtype}
                        getOptionLabel={(v) => v.subtype}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedSubtype==null &&
                        <div className="text-danger">{t('Required')}</div>
                      }
                    </Col>
                    <Col md="3">
                      <Label className="form-Label">{t("Location")}</Label>
                      <ReactSelect
                        name="incident_type"
                        options={incidentLocations ? incidentLocations : []}
                        value={selectedLocation}
                        isClearable={true}
                        onChange={(option) => {
                          setSelectedLocation(option)
                        }}
                        placeholder={t("Select") + "..."}
                        getOptionValue={(v) => v.location}
                        getOptionLabel={(v) => v.location}
                        isMulti={true}
                      />
                      {touched && selectedLocation==null &&
                        <div className="text-danger">{t('Required')}</div>
                      }
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="3">
                      <div className="form-check mt-2">
                        <Input
                          name="type"
                          id="type1"
                          className="radio"
                          type="radio"
                          checked={selectedRadio == 1}
                          onClick={() => setSelectedRadio(1)}
                        />
                        <Label className="form-Label" for="type1">
                          {t("Report by days")}
                        </Label>
                      </div>
                      <div className="form-check">
                        <Input
                          name="type"
                          id="type2"
                          className="radio"
                          type="radio"
                          checked={selectedRadio == 2}
                          onClick={() => setSelectedRadio(2)}
                        />
                        <Label className="form-Label" for="type2">
                          {t("Report by hours")}
                        </Label>
                      </div>
                    </Col>
                    { selectedRadio == 1 &&
                      <>
                      <Col md="3">
                        <Label className="form-Label">{t("Start date")}</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={selectedPeriod.start}
                          onChange={(date) =>
                            setSelectedPeriod({ ...selectedPeriod, start: date })
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                        {touched && selectedPeriod.start==null &&
                        <div className="text-danger">{t('Required')}</div>
                      }
                      </Col>
                      <Col md="3">
                        <Label className="form-Label">{t("End date")}</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={selectedPeriod.end}
                          onChange={(date) =>
                            setSelectedPeriod({ ...selectedPeriod, end: date })
                          }
                          dateFormat="dd/MM/yyyy"
                          filterTime={filterPassedTime}
                        />
                        {touched && selectedPeriod.end==null &&
                          <div className="text-danger">{t('Required')}</div>
                        }
                      </Col>
                      </>
                    }
                    { selectedRadio == 2 &&
                      <>
                      <Col md="3">
                        <Label className="form-Label">{t("Start date")}</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={selectedPeriod.start}
                          onChange={(date) =>
                            setSelectedPeriod({ ...selectedPeriod, start: date })
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                        {touched && selectedPeriod.start==null &&
                          <div className="text-danger">{t('Required')}</div>
                        }
                      </Col>
                      <Col md="3">
                        <Label className="form-Label">{t("End date")}</Label>
                        <ReactDatePicker
                          className="form-control"
                          selected={selectedPeriod.end}
                          onChange={(date) =>
                            setSelectedPeriod({ ...selectedPeriod, end: date })
                          }
                          dateFormat="dd/MM/yyyy"
                          filterTime={filterPassedTime}
                        />
                        {touched && selectedPeriod.end==null &&
                          <div className="text-danger">{t('Required')}</div>
                        }
                      </Col>
                      </>
                    }
                    
                    <Col md="3" className="text-right">
                      <Button
                        className="btn btn-danger btn-block mt-4 me-2"
                        type="button"
                        onClick={fetchReport}
                        disabled={loadingReport}
                      >
                        <i className="fa fa-list-alt"></i> {t("Create Report")}
                      </Button>
                      <Button
                        className="btn btn-danger btn-block mt-4"
                        type="button"
                        onClick={ resetFields }
                      >
                        <i className="fa fa-redo"></i> {t("Reset")}
                      </Button>
                    </Col>
                  </Row>

                  {report.types?.summary?.length == 0 && 
                   report.subtypes?.summary?.length == 0 && 
                   report.locations?.summary?.length == 0 &&
                    <div className="mt-5 alert alert-dark text-center">{t("No data available for this selection")}</div>
                  }

                  {report.types?.summary?.length > 0 &&
                  <>
                    <h4 className="mt-5 text-center">{t('Incident Types')}</h4>
                    <Row className="mt-2">
                      <Col md="6">
                        <div id="pie-chart" className="e-chart pt-3">
                          {<Piet data={report.types.summary} label="type" />}
                        </div>
                      </Col>
                      <Col md="6">
                        <div id="pie-chart" className="e-chart">
                          {
                            selectedRadio == 1 ? 
                            <LineChartDate data={report?.types?.summaryByPeriod} period={selectedPeriod} label="type"/> :
                            <LineChartTime data={report?.types?.summaryByPeriod} period={selectedPeriod} label="type"/>
                          }
                        </div>
                      </Col>
                    </Row>
                  
                    <Row className="mt-2">
                      <Col xl="12">
                        <Table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="table-light table-sm">
                            <tr>
                              <th style={{width: '100px'}}>#</th>
                              <th>{t("Incident Type")}</th>
                              <th>{t("Reports")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(report?.types?.summary ?? []).map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{e.type}</td>
                                  <td>{e.total}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                  }

                  {report.subtypes?.summary?.length > 0 &&
                  <>
                    <h4 className="mt-5 text-center">{t('Incident Subtypes')}</h4>
                    <Row className="mt-2">
                      <Col md="6">
                        <div id="pie-chart" className="e-chart pt-3">
                          {<Piet data={report.subtypes.summary} label="subtype" />}
                        </div>
                      </Col>
                      <Col md="6">
                        <div id="pie-chart" className="e-chart">
                          {
                            selectedRadio == 1 ? 
                            <LineChartDate data={report?.subtypes?.summaryByPeriod} period={selectedPeriod} label="subtype"/> :
                            <LineChartTime data={report?.subtypes?.summaryByPeriod} period={selectedPeriod} label="subtype"/>
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xl="12">
                        <Table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="table-light ">
                            <tr>
                              <th style={{width: '100px'}}>#</th>
                              <th>{t("Incident Subtype")}</th>
                              <th>{t("Reports")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(report?.subtypes?.summary ?? []).map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{e.subtype}</td>
                                  <td>{e.total}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                  }

                  {report.locations?.summary?.length > 0 &&
                  <>
                    <h4 className="mt-5 text-center">{t('Locations')}</h4>
                    <Row className="mt-2">
                      <Col md="6">
                        <div id="pie-chart" className="e-chart pt-3">
                          {<Piet data={report.locations.summary} label="location" />}
                        </div>
                      </Col>
                      <Col md="6">
                        <div id="pie-chart" className="e-chart">
                          {
                            selectedRadio == 1 ? 
                            <LineChartDate data={report?.locations?.summaryByPeriod} period={selectedPeriod} label="location"/> :
                            <LineChartTime data={report?.locations?.summaryByPeriod} period={selectedPeriod} label="location"/>
                          }
                        </div>
                      </Col>
                    </Row>
                  
                    <Row className="mt-2">
                      <Col xl="12">
                        <Table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="table-light table-sm">
                            <tr>
                              <th style={{width: '100px'}}>#</th>
                              <th>{t("Location")}</th>
                              <th>{t("Reports")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(report?.locations?.summary ?? []).map((e, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{idx + 1}</td>
                                  <td>{(e.location.length > 0) ? e.location : t('<location not specified>')}</td>
                                  <td>{e.total}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(IncidentsByEntity)
