import React from "react"
import { Redirect } from "react-router-dom"

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import userProfile from "../pages/Authentication/user-profile";
import CountriesList from '../pages/Catalogs/Countries/List/countries-list';
import LanguagesList from '../pages/Catalogs/Languages/List/languages-list';
import TranslationsList from '../pages/Catalogs/Translations/List/translations-list';
import CurrentList from "../pages/Incidents/CurrentList";
import ClientsList from '../pages/Clients/clients-list';
import historyReport from "../pages/Reports/History/List/history-report";
import IncidentsByEntity from "../pages/Reports/IncidentsByEntity/IncidentsByEntity";
import InstallationsList from "../pages/Catalogs/Installations/List/installations-list";
import ProvidersList from "../pages/Providers/providers-list";
import UsersList from "../pages/Users/users-list";
import clientsDetail from "../pages/Clients/Details/clients-detail";
import SubAdminList from "../pages/SubAdmins/SubAdminList";
import ResponseTeamsList from "../pages/ResponseTeams/ResponseTeamsList";
import EntitiesList from "../pages/Entities/EntitiesList";
import EntityDetail from "../pages/Entities/Details/EntityDetail";
import ResponseTeamsUserList from "../pages/ResponseTeamUser/ResponseTeamsUserList";
import IncidentTypesList from "../pages/IncidentTypes/IncidentTypesList";
import IncidentSubTypesList from "../pages/IncidentSubtypes/IncidentSubTypesList";
import LocationsList from "../pages/Locations/LocationsList";
import LockScreen from "../pages/Authentication/LockScreen";
import installationEdit from "../pages/Installation/installation-edit";
import emailTemplatesList from "../pages/Catalogs/EmailTemplates/List/email-templates-list";
import clientAreasList from "../pages/Catalogs/ClientAreas/List/client-areas-list";
import CurrentIncidentsList from "../pages/Incidents/CurrentIncidentsList";
import ClosedIncidentsList from "../pages/Incidents/ClosedIncidentsList";
import IncidentDetail from "../pages/Incidents/Details/IncidentDetail";
import SectionsList from "../pages/Catalogs/Sections/List/sections-list";
import clientConfig from "../pages/Clients/client-config";
import NoticesList from "../pages/Notices/NoticesList";
import SponsorsList from "../pages/Sponsors/SponsorsList";
import SysadminConfig from "../pages/sysadmin-config";
import SponsorDetail from "../pages/Sponsors/Details/SponsorDetail";
import SponsorsContractsList from "../pages/Sponsors/SponsorsContractsList";
import SponsorsAdvertsementsList from "../pages/Sponsors/SponsorsAdvertsementsList";
import ViewsReport from "../pages/Reports/ViewsReport/ViewsReport";
import ViewClicksReport from "../pages/Reports/ViewClicksReport/ViewClicksReport";
import sysadminLanguages from "../pages/sysadmin-languages";
import clientLanguages from "../pages/Clients/client-languages";

const userRoutes = [

  //dashboard
  { path: "/dashboard", component: Dashboard },

  // Managment
  { path: "/managment/installation", component: installationEdit },
  { path: "/managment/sponsors", component: SponsorsList },
  { path: "/managment/sponsors/detail/:id", component: SponsorDetail },
  { path: "/managment/sponsors/contracts", component: SponsorsContractsList },
  { path: "/managment/sponsors/advertisements", component: SponsorsAdvertsementsList },
  { path: "/managment/clients", component: ClientsList },
  { path: "/managment/clients/detail/:id", component: clientsDetail },
  { path: "/managment/providers", component: ProvidersList },
  { path: "/managment/users", component: UsersList },
  { path: "/managment/subadmins", component: SubAdminList },
  { path: "/managment/response-teams", component: ResponseTeamsList },
  { path: "/managment/response-team-users", component: ResponseTeamsUserList },
  { path: "/managment/entities", component: EntitiesList },
  { path: "/managment/entities/detail/:id", component: EntityDetail },
  { path: "/managment/incident-types", component: IncidentTypesList },
  { path: "/managment/incident-subtypes", component: IncidentSubTypesList },
  { path: "/managment/locations", component: LocationsList },
  { path: "/managment/sections", component: SectionsList },
  { path: "/managment/notices", component: NoticesList },
  { path: "/managment/configuration", component: clientConfig },
  { path: "/managment/languages", component: clientLanguages },
  { path: "/managment/sys-configuration", component: SysadminConfig },
  { path: "/managment/sys-languages", component: sysadminLanguages },
  
  
  { path: "/catalogs/languages", component: LanguagesList },
  { path: "/catalogs/translations", component: TranslationsList },
  { path: "/catalogs/countries", component: CountriesList },
  { path: "/catalogs/installations", component: InstallationsList },
  { path: "/catalogs/email-templates", component: emailTemplatesList },
  { path: "/catalogs/client-areas", component: clientAreasList },
  
  
  // Incidents
  { path: "/incidents/work-on-old", component: CurrentList },
  { path: "/incidents/work-on", component: CurrentIncidentsList },
  { path: "/incidents/work-on/detail", component: IncidentDetail },
  { path: "/incidents/work-on/:id", component: IncidentDetail },
  { path: "/incidents/history", component: ClosedIncidentsList },

  // Reports
  { path: "/reports/history", component: historyReport },
  { path: "/reports/incidents-by-entity", component: IncidentsByEntity },
  { path: "/reports/visits-by-entity", component: ViewsReport },
  { path: "/reports/visits-click", component: ViewClicksReport },

  //profile
  { path: "/profile", component: userProfile },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/page-lock-screen", component: LockScreen },
]

const publicRoutes = []


const externalRoutes = [
  //external pages  
  { path: "/terms-and-conditions", component: () => { window.location.href = '/terms-and-conditions.html'; return null} },
  { path: "/privacy-policy", component: () => { window.location.href = '/privacy-policy.html'; return null}},
]

export { userRoutes, authRoutes, publicRoutes, externalRoutes }
