import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import * as moment from 'moment'
import _ from 'lodash'

class LineChartDate extends Component {
  getOption = () => {
    const { t, data, period, label } = this.props;

    const xDates = []
    const yData = []
    let curDate = moment(period?.start)
    const endDate = moment(period?.end)
    
    const seriesIndx = _.uniq(data.map(e => e[label]))
    const seriesData = {}
    seriesIndx.forEach(e => { seriesData[e] = {data:[], type: 'line'}; })

    for(;curDate.isBefore(endDate); curDate.add(1,'days')){
      xDates.push(curDate.format('DD/MM/YYYY'))
      for(let i=0; i<seriesIndx.length; i++) {
        let items =  data.filter( e => { 
          return e[label] == seriesIndx[i] && curDate.isSame(moment(e.date.substring(0,10)), 'date') 
        } );
        seriesData[seriesIndx[i]].data.push(items[0]?.total ?? 0 )
      }
    }
    console.log('seriesIndx',seriesIndx)
    console.log('seriesData',seriesData)
    return {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: xDates,
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: seriesIndx.map(e => {return seriesData[e]}),
      color: ['#ED5565', '#FC6E51', '#FFCE54', '#A0D468', '#48CFAD', '#4FC1E9', '#5D9CEC', '#AC92EC', '#EC87C0', '#F5F7Fa', '#CCD1D9', '#656D78', '#DA4453', '#E9573F', '#F6BB42', '#8CC152', '#37BC9B', '#3BAFDA', '#4A89DC', '#967ADC', '#D770AD', '#E6E9ED', '#AAB2BD', '#434A54'],
      textStyle: {
        color: ["#74788d"],
      },
    };
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} notMerge={true} />
      </React.Fragment>
    )
  }
}
export default LineChartDate
