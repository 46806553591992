import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addIncidentType, addLocation, addResponseTeam, deleteIncidentType, deleteLocation, deleteUser, getIncidentTypes, getLanguagesClientSelect, getLanguagesSystemSelect, getLocations, getResponseTeams, getTeamsSelect, postImage, putUserResetPassword, updateIncidentType, updateLocation, updateResponseTeam } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import ConfirmModal from "../../components/Common/ConfirmModal";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import classNames from "classnames";
import getAuthUser from "../../components/helpers/auth";
import ReactSelect from "react-select";
import Dropzone from "react-dropzone";
import MapPicker from "../../components/MapPicker";
import ConfirmModalYesNo from "../../components/Common/ConfirmModalYesNo";
import i18n from "../../i18n";

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const LocationsList = props => {
  const t = props.t;
  const masterItemName = t('Location');
  const masterItemNamePlural = t('Locations');
  const currentUser = getAuthUser()
  const typeOptions = [
    {value: 'STANDAR', label: t('STANDAR')},
    {value: 'INDIVIDUAL', label: t('INDIVIDUAL')}
  ]
  //meta title
  document.title = masterItemName + "List | Tellit";
  const [langs, setLangs] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [teams, setTeams] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalConfirmUpdate, setModalConfirmUpdate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  const [updateAsset, setUpdateAsset] = useState (false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getLocations({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchCliLanguages = async () => {
    const items = await getLanguagesClientSelect()
    setLangs(items)
  }

  useEffect(() => {
    fetchData()
    fetchCliLanguages()
  }, [tableOpts])

  const createValidationSchema = () => {
    const validation = {
      //name: Yup.string().trim().required(t("required")).min(1),
      description: Yup.string(),
      assetId: Yup.string().required(t("required")),
      sort_order: Yup.number().required(t("required")),
      type: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      entityRelated: Yup.bool(),
      incidentSubtypeRelated: Yup.bool(),
      showLocationName: Yup.bool(),
      latitude: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")),
        otherwise: Yup.number().nullable()
      }),
      longitude: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")),
        otherwise: Yup.number().nullable()
      }),
      maxRadius: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")).min(1, 'min required is 1km'),
      }),
    }
    langs.forEach((lang) => { validation['name_' + lang.code] = Yup.string().required(t("required")).min(1) })
    return Yup.object(validation)
  }

  const initValues = () => {
    let values = {
      position: item?.latitude ? {lat: Number(item?.latitude), lng: Number(item?.longitude)}:{lat: 19.4009907, lng: -99.1767005},
      name: (item && item.name) || "",
      description: (item?.description) || "",
      assetId: (item?.assetId) || '',
      sort_order: (item?.sort_order) || "",
      type: (item?.type) ? {value: item?.type, label: item?.type} : {value: '', label:''},
      entityRelated: (item) ? item.entityRelated : true,
      incidentSubtypeRelated: (item) ?  (item?.incidentSubtypeRelated) : true,
      showLocationName: (item?.showLocationName) || false,
      restrictReportLocation: (item?.maxRadius > 0) ? true : false,
      latitude: (item?.latitude) ? Number(item?.latitude) : null,
      longitude: (item?.longitude) ? Number(item?.longitude) : null,
      maxRadius: (item?.maxRadius) || 0
    }
    langs.forEach((lang) => { values['name_' + lang.code] = item?.['name_lang']?.[lang.code] || "" })

    return values
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initValues(),
    validationSchema: createValidationSchema,
    onSubmit: async values => {
      console.log('values',values)
      let name_lang = {}
      langs.forEach((lang) => { name_lang[lang.code] = values['name_'+lang.code] || ""; delete values['name_'+lang.code]})

      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
          name: name_lang[langs[0].code],
          name_lang: JSON.stringify(name_lang),
          entityRelated: values.entityRelated ? true : false,
          incidentSubtypeRelated: values.incidentSubtypeRelated ? true : false,
          type: values.type?.value,
          showLocationName: values.showLocationName ? true : false,
          maxRadius: Number(values.maxRadius),
          restrictReportLocation: values.restrictReportLocation ? true : false
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateLocation(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])
      } else {
        const newItem = {
          ...values,
          name: name_lang[langs[0].code],
          name_lang: JSON.stringify(name_lang),
          entityRelated: values.entityRelated ? true : false,
          incidentSubtypeRelated: values.incidentSubtypeRelated ? true : false,
          type: values.type?.value,
          showLocationName: values.showLocationName ? true : false,
          maxRadius: Number(values.maxRadius),
          restrictReportLocation: values.restrictReportLocation ? true : false
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addLocation(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])
      }
      toggle();
    },
  });

  const checkIfModalConfirmUpdate = () => {
    const anyUpdate = fieldsMarks.filter(e => { return validation.values[e+'_up'] === true || validation.values[e+'_over'] === true }).length > 0
    if(anyUpdate) {
      setModalConfirmUpdate(true)
      return
    }
    handleUploadFile()
  }

  const handleUploadFile = async () => {
    if (!isEdit || updateAsset) {
      validation.setTouched({...validation.touched, assetId: true})
      if (selectedFiles.length == 0) {
        validation.setValues({...validation.values, assetId: ''})
        validation.submitForm()
        return;
      }
      const file = await submissionFiles();
      validation.values.assetId = file.id
    }
    validation.submitForm()
    console.log('complete upload file')
  }

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'asset.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => ( row.asset ? <img width="100" src={API_URL + '/' + row.asset.path}></img>:t('-')),
      sort: false
    }, {
      dataField: 'name',
      text: t('Name'),
      sort: true,
      formatter: (cell, row, rIdx) => ( row.name_lang[i18n.language] ?? row.name ),
    }, {
      dataField: 'type',
      text: t('Type'),
      sort: true,
      formatter: (cell, row, rIdx) => ( t(row.type)),
    }, {
      dataField: 'entityRelated',
      text: t('Entity Related'),
      sort: true,
      formatter: (cell, row, rIdx) => ( t(row.entityRelated ? 'Yes':'No')),
    }, {
      dataField: 'incidentSubtypeRelated',
      text: t('Incident Subtype Related'),
      sort: true,
      formatter: (cell, row, rIdx) => ( t(row.incidentSubtypeRelated ? 'Yes':'No')),
    }, {
      dataField: 'sort_order',
      text: t('Sort Order'),
      sort: true
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    console.log('toogle')
    setModal(!modal)
  };

  const [deleteModal, setDeleteModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const handleDeleteUser = async () => {
    const response = await deleteLocation(item.id)
    fetchData()
    setDeleteModal(false)
  }

  const handleItemCreateClick = () => {
    setUpdateAsset(false)
    setItem(null)
    setIsEdit(false)
    toggle()
  }

  const handleItemDeleteClick = row => {
    setItem(row)
    setDeleteModal(true)
  }

  const handleItemEditClick = (row) => {
    setUpdateAsset(row.asset ? false : true)
    validation.resetForm()
    setItem(row)
    setIsEdit(true)
    toggle()
  }

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    validation.setFieldValue('assetId','1',true)
    validation.setFieldTouched('assetId', true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const submissionFiles = async () => {
    let result = await postImage(selectedFiles[0])
    return result
	}

  const fieldsMarks = ['name', 'description', 'sort_order', 'type', 'entityRelated','incidentSubtypeRelated','supportMedia','requiredSupportMedia','supportText','requiredSupportText','showLocationName','askQuestionMandatory','askQuestion','latitude','longitude','maxRadius','assetId','restrictReportLocation']
  const checkAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',true] }))
    })
  }
  
  const uncheckAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',false] }))
    })
  }

  const checkAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',true] }))
    })
  }

  const uncheckAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',false] }))
    })
  }

  console.log('tableOpts', tableOpts)
  console.log('modal', modal)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        message={t('delete_catalog_location_ask_confirm')}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} style={{maxWidth: '60%'}}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row className={{"border-bottom":true, "border-1":true , "pb-2":true, 'd-none': !isEdit}}>
                                <Col xs={isEdit ? 8:12}></Col>
                                <Col xs={2} className="text-center">
                                  {t('Update entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllUpdateFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllUpdateFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                                <Col xs={2} className="text-center">
                                  {t('Update and overwrite entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllOverwriteFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllOverwriteFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3 d-none">
                                    <Label className="form-label">{t("Name")}</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                          validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                      validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  
                                  <Label className="form-label d-inline">{t("Name")}</Label>
                                  {
                                    langs.map((lang) => (
                                      <div className="mb-3 mt-2" key={lang.id}>
                                        <div className="row">
                                          <div className="col-1 text-center">
                                            <Label className="form-label ">{lang.code?.toUpperCase()}</Label>
                                          </div>
                                          <div className="col-11">
                                            <Input
                                              name={"name_"+lang.code}
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values["name_"+lang.code] || ""}
                                              invalid={
                                                validation.touched["name_"+lang.code] &&
                                                  validation.errors["name_"+lang.code]
                                                  ? true
                                                  : false
                                              }
                                            />
                                            { validation.touched["name_"+lang.code] &&
                                                validation.errors["name_"+lang.code] ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors["name_"+lang.code]}
                                                </FormFeedback>
                                              ) : null
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  }
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_lang_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_lang_up', !evt.target.checked , true)}}
                                      checked={validation.values.name_lang_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_lang_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_lang_over', !evt.target.checked , true)}}
                                      checked={validation.values.name_lang_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Description")}</Label>
                                    <Input
                                      name="description"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.description || ""}
                                      invalid={
                                        validation.touched.description &&
                                          validation.errors.description
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.description &&
                                      validation.errors.description ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.description}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="description_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('description_up', !evt.target.checked , true)}}
                                      checked={validation.values.description_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="description_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('description_over', !evt.target.checked , true)}}
                                      checked={validation.values.description_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Sort Order")}</Label>
                                    <Input
                                      name="sort_order"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.sort_order || ""}
                                      invalid={
                                        validation.touched.sort_order &&
                                          validation.errors.sort_order
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.sort_order &&
                                      validation.errors.sort_order ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sort_order}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_up', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_over', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Type')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors?.type?.value ? true:false})}
                                      name="type"
                                      options={typeOptions}
                                      value={validation.values.type || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('type', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('type', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.type?.value &&
                                      validation.errors.type?.value ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.type?.value}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Entity Related")}</Label> <Input
                                        name="entityRelated"
                                        label="entityRelated"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('entityRelated', !evt.target.checked , true)}}
                                        checked={validation.values.entityRelated}
                                      />
                                  </div>
                                </Col>
                                
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Subtype Incident Related")}</Label> <Input
                                        name="incidentSubtypeRelated"
                                        label="incidentSubtypeRelated"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('incidentSubtypeRelated', !evt.target.checked , true)}}
                                        checked={validation.values.incidentSubtypeRelated}
                                      />
                                  </div>
                                </Col>
                                
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Show Location Name")}</Label> <Input
                                        name="showLocationName"
                                        label="showLocationName"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('showLocationName', !evt.target.checked , true)}}
                                        checked={validation.values.showLocationName}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="showLocationName_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('showLocationName_up', !evt.target.checked , true)}}
                                      checked={validation.values.showLocationName_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="showLocationName_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('showLocationName_over', !evt.target.checked , true)}}
                                      checked={validation.values.showLocationName_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mt-2">
                                    <Label className="form-label">{t("Only allow an incident within a radius")}</Label> <Input
                                        name="restrictReportLocation"
                                        label="restrictReportLocation"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('restrictReportLocation', !evt.target.checked , true); if((!evt.target.checked) == false) validation.setFieldValue('maxRadius', 0, true)}}
                                        checked={validation.values.restrictReportLocation}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    <div className="mt-2">
                                      <Input
                                        name="restrictReportLocation_up"
                                        type="checkbox"
                                        onClick={(evt) => { 
                                          validation.setFieldValue('restrictReportLocation_up', !evt.target.checked , true)
                                          validation.setFieldValue('longitude_up', !evt.target.checked , true)
                                          validation.setFieldValue('maxRadius_up', !evt.target.checked , true)
                                        }}
                                        checked={validation.values.restrictReportLocation_up || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    <div className="mt-2">
                                      <Input
                                        name="restrictReportLocation_over"
                                        type="checkbox"
                                        onClick={(evt) => { 
                                          validation.setFieldValue('restrictReportLocation_over', !evt.target.checked , true)
                                          validation.setFieldValue('longitude_over', !evt.target.checked , true)
                                          validation.setFieldValue('maxRadius_over', !evt.target.checked , true)
                                        }}
                                        checked={validation.values.restrictReportLocation_over || ""}
                                      />
                                    </div>
                                  </Col>                          
                              </Row>
                              { validation.values.restrictReportLocation &&
                              <>
                                <Row className="border-bottom border-1">
                                  <Col xs={isEdit ? 8:12}>
                                    <MapPicker
                                      position={validation.values.position}
                                      onChange={ (position) => { 
                                        validation.setFieldValue('position', position) 
                                        validation.setFieldValue('latitude', position.lat) 
                                        validation.setFieldValue('longitude', position.lng) 
                                      }}
                                    ></MapPicker>
                                  </Col>
                                </Row>
                                <Row className="border-bottom border-1">
                                  <Col xs={isEdit ? 8:12}>
                                      <Row>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Latitude")}</Label>
                                            <Input
                                              name="latitude"
                                              type="number"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.latitude || ""}
                                              invalid={
                                                validation.touched.latitude &&
                                                  validation.errors.latitude
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.latitude &&
                                              validation.errors.latitude ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.latitude}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Longitude")}</Label>
                                            <Input
                                              name="longitude"
                                              type="number"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.longitude || ""}
                                              invalid={
                                                validation.touched.longitude &&
                                                  validation.errors.longitude
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.longitude &&
                                              validation.errors.longitude ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.longitude}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                  </Col>
                                  <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    { validation.values.restrictReportLocation && 
                                      <div className="mt-2">
                                        <Input
                                          name="latitude_up"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('latitude_up', !evt.target.checked , true); validation.setFieldValue('longitude_up', !evt.target.checked , true)}}
                                          checked={validation.values.latitude_up || ""}
                                        />
                                      </div>
                                    }
                                  </Col>
                                  <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    { validation.values.restrictReportLocation && 
                                      <div className="mt-2">
                                        <Input
                                          name="latitude_over"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('latitude_over', !evt.target.checked , true); validation.setFieldValue('longitude_up', !evt.target.checked , true)}}
                                          checked={validation.values.latitude_over || ""}
                                        />
                                      </div>
                                    }
                                  </Col>
                                </Row>
                                <Row className="border-bottom border-1">
                                  <Col xs={isEdit ? 8:12}>
                                    <div className="mb-3">
                                      <Label className="form-label">{t("Report Only Radius")} {t('(kms)')}</Label>
                                      <Input
                                        name="maxRadius"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.maxRadius || ""}
                                        invalid={
                                          validation.touched.maxRadius &&
                                            validation.errors.maxRadius
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.maxRadius &&
                                        validation.errors.maxRadius ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.maxRadius}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    { validation.values.restrictReportLocation && 
                                      <div className="mt-2">
                                        <Input
                                          name="maxRadius_up"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('maxRadius_up', !evt.target.checked , true)}}
                                          checked={validation.values.maxRadius_up || ""}
                                        />
                                      </div>
                                    }
                                  </Col>
                                  <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    { validation.values.restrictReportLocation && 
                                      <div className="mt-2">
                                        <Input
                                          name="maxRadius_over"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('maxRadius_over', !evt.target.checked , true)}}
                                          checked={validation.values.maxRadius_over || ""}
                                        />
                                      </div>
                                    }
                                  </Col>
                                </Row>
                              </>
                              }
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  { isEdit && item.asset &&
                                  <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                                    <Label className="form-label">{t("Current Asset")}</Label><br />
                                    <img height="100" src={API_URL + '/' + item.asset.path}></img><br />
                                    <button
                                      type="button"
                                      onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                                      className="btn btn-primary btn-sm save-user mt-1"
                                    >
                                      <i className="fas fa-sync"></i> {t("Change")}
                                    </button>
                                  </div>
                                  }
                                  <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                      <Label className="form-label">{t("Icon")}</Label><br />
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          validation.setFieldTouched('assetId', true, true)
                                          handleAcceptedFiles(acceptedFiles)
                                        }}
                                        onFileDialogOpen={() => {
                                          validation.setFieldTouched('assetId', true, true)
                                        }}
                                        onDropAccepted={() => {
                                          validation.setFieldTouched('assetId', true, true) 
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone" style={{minHeight: '120px'}}>
                                            <div
                                              className="text-center needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-5 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h5>{ t('Drop files here or click to upload') }</h5>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted font-weight-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>                                                    
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                  </div>
                                  {validation.touched.assetId &&
                                        validation.errors.assetId ? (
                                        <FormFeedback type="invalid" className="invalid">
                                          {validation.errors.assetId}
                                        </FormFeedback>
                                      ) : null}
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_up', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_over', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center mt-2">
                                    <button
                                    type="button"
                                      onClick={checkIfModalConfirmUpdate}
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmModalYesNo
        setShow={setModalConfirmUpdate}
        show={modalConfirmUpdate}
        text={t('update_cascade_locations_confirm')}
        onConfirmClick={() => {handleUploadFile(); setModalConfirmUpdate(false) }}
        onCloseClick={() => { uncheckAllUpdateFields(); uncheckAllUpdateFields(); handleUploadFile(); setModalConfirmUpdate(false) } }
      />
    </React.Fragment>
  )
}

export default withTranslation()(LocationsList)
