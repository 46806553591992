import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Card, CardBody, Col, Row } from "reactstrap";
import MapReport from "./MapReport";
import { getIncidentTracks } from "../../../helpers/backend_helper";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";

const TabGpsFollow = ({ incident = null, t }) => {
  const [trackList, setTrackList] = useState([]);

  const fetchItems = async () => {
    const items = await getIncidentTracks(incident.id);
    setTrackList(items);
  };

  useEffect(() => {
    if (!incident) return;
    fetchItems();
    const interval = setInterval(() => {
      fetchItems();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [incident]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md="6">
            <div className="table-responsive" style={{maxHeight:'400px'}}>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr className="bg-light">
                    <th className="text-center" colSpan={4}>
                      {t("Position Table")}
                    </th>
                  </tr>
                  <tr className="bg-light">
                    <th>{t("Server Date")}</th>
                    <th>{t("Device Date")}</th>
                    <th>{t("On-line")}</th>
                    <th>
                      {t("Latitude")} / {t("Longitude")}
                    </th>
                  </tr>
                </thead>
                <tbody className="small">
                  {trackList.map((e, idx) => (
                    <tr key={idx}>
                      <td>
                        {e.created_at ? dayjs(e.created_at).format("DD/MM/YYYY HH:mm:ss"):'-'}
                      </td>
                      <td>
                        {e.date ? dayjs(e.date).format("DD/MM/YYYY HH:mm:ss"):'-'}
                      </td>
                      <td>
                        { e.headless == null ? '-' : (e.headless ? "No" : "Si") }
                      </td>
                      <td>
                        {e.lat}, {e.lng}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
          <Col md="6">
            MAPA
            <MapContainer
              center={
                trackList.length > 0
                  ? [trackList[0].lat, trackList[0].lng]
                  : [19.432608, -99.133209]
              }
              zoom={13}
              scrollWheelZoom={true}
              style={{ height: "400px", width: "100%" }}
            >
              <TileLayer
                attribution=""
                url="http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              />

              <MapReport
                positions={trackList}
                marker={
                  trackList.length > 0
                    ? [trackList[0].lat, trackList[0].lng]
                    : [19.432608, -99.133209]
                }
              />
            </MapContainer>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(TabGpsFollow);
