import React, { useEffect, useState, useContext } from "react";

import { Marker, useMap, Circle, Polyline } from "react-leaflet";

import * as _ from "lodash";

const blackOptions = { color: "black", fillColor: "black" };
const redOptiions = { color: "red" };

const MapReport = ({ positions = [], marker = null }) => {
  const map = useMap();
  const [bounds, setBounds] = useState([]);


  useEffect(() => {
    if ((positions?.length ?? "") == 0) return;

    let bounds = positions.map((e) => [e?.lat, e?.lat]);
    map.fitBounds(bounds);
    setBounds(bounds);
  }, [positions]);

  useEffect(() => {
    if (marker != null) map.setView(marker);
  }, [marker]);

  return (
    <>
      <Polyline pathOptions={redOptiions} positions={bounds} />

      {positions.map((item, index) => (
        <Circle
          key={index}
          center={[item.lat, item.lng]}
          pathOptions={blackOptions}
          radius={1}
        />
      ))}

      {marker && <Marker position={marker}></Marker>}
    </>
  );
};

export default MapReport;
