import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, Button, Label, Form } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../components/Common/Breadcrumb";

// actions
import { withTranslation } from "react-i18next";
import { getConfigs, getLanguagesSelect, setConfigs } from "../helpers/backend_helper";
import NibbleInput from "../components/Fields/NibbleInput";
import { createFilter } from "react-select/dist/react-select.cjs.prod";

const SysadminLanguages = (props) => {
  const t = props.t;
  const masterItemName = t("Base languages");
  //meta title
  document.title = masterItemName + " | Tellit";

  const [item, setItem] = useState({});
  const [langs, setLangs] = useState([]);
  const [dlangs, setDlangs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      base_languages: item?.base_language?.value || [],
      default_language: item?.default_language?.value || "en",
    },
    validationSchema: Yup.object({
      base_languages: Yup.array().min(1,'Debe seleccionar 1 o más'),
      default_language: Yup.string().nullable().required(t("required")),
    }),
    onSubmit: async (values) => {
      console.log("values", values);

      const updateItem = [
        {
          name: "BASE_LANGUAGES".toUpperCase(),
          value: langs.filter((i) => { return values.base_languages.includes(i.id) }).map((lang) => lang.code).join(',')
        },
        {
          name: "DEFAULT_LANGUAGE".toUpperCase(),
          value: _.find(langs, {'id': values.default_language}).code,
        },
      ];

      setLoadingBtn(true);
      const itemResponse = await setConfigs({ configs: updateItem });
      fetchData();
      setLoadingBtn(false);

      validation.resetForm();
    },
  });

  const fetchLanguages = async () => {
    const items = await getLanguagesSelect()

    setLangs(items)
    setLoading(false)

    return items
  }

  const fetchData = async () => {
    let langs = await fetchLanguages()
    const item = await getConfigs()

    console.log('configs', item)
    item.forEach((e) => {
      const name = e.name.toLowerCase()
      if(['base_languages', 'default_language'].includes(name)) {
        if('base_languages' === name) {
          console.log(langs.filter((lang) => e.value.split(',').includes(lang.code)).map((lang) => lang.id))
          validation.setFieldValue(name, langs.filter((lang) => e.value.split(',').includes(lang.code)).map((lang) => lang.id), true)
        }
        if('default_language' === name) {
          let tmp = langs.filter((lang) => e.value.includes(lang.code))
          validation.setFieldValue(name, tmp?.[0]?.id ?? null, true)
        }
      }
    })

    setLoading(false)
  };

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setDlangs(langs.filter((lang) => validation.values.base_languages?.includes(lang.id)))
    if(!validation.values.base_languages.includes(validation.values.default_language)){
      validation.setFieldValue('default_language', null, true)
    }
  }, [validation.values.base_languages])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={t("System")} breadcrumbItem={masterItemName} />

          <Card style={{minHeight: '400px'}}>
            <CardBody>
              {loading && (
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              )}
              {!loading && (
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  
                  <div className="mb-3">
                    <NibbleInput
                      md={12}
                      placeHolder={t("Select")}
                      label={t(
                        "App supported languages"
                      )}
                      fieldName="base_languages"
                      fieldType="select-multiple"
                      validation={validation}
                      selectItems={langs}
                      isMulti={true}
                      selectOptionValue={(option) => option.id}
                      selectOptionLabel={(option) => `${option.code} - ${option.native}`}
                      selectOnChange={(option, meta) => {
                        console.log(option)
                        validation.setFieldValue(base_languages, option?.code ?? null, true);
                      }}
                      filterOption={createFilter({
                        matchFrom: "any",
                        stringify: (option) => `${option.code} - ${option.native}`,
                      })}
                    />

                    <NibbleInput
                      md={6}
                      placeHolder={t("Select")}
                      label={t(
                        "Default language"
                      )}
                      fieldName="default_language"
                      fieldType="select"
                      validation={validation}
                      selectItems={dlangs}
                      selectOptionLabel={(option) => `${option.code} - ${option.native}`}
                    />

                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      <i className="fa fa-save"></i> {t("Save")}
                    </Button>
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SysadminLanguages);
