import React, { useState } from "react";
import "@vtaits/react-color-picker/dist/index.css";

import { Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { Chrome } from "../../../node_modules/react-color/es/components/chrome/Chrome";
import { ChromePicker } from "react-color";

const NibbleInputColor = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  validation,
}) => {
  const [simple_color1, setsimple_color1] = useState(0);

  const onChangeDo = (a) => {
    console.log(a);
    validation.setFieldValue(fieldName, a.hex, true);
  };

  const colorRgb = validation.values[fieldName]

  return (
    <Col className="mt-2" md={md}>
      <Row>
        <Col md={6}>
          <FormGroup className="m-b-0">
            <Label>{label}</Label>
            <div
              className="input-group colorpicker-default"
              title="Using format option"
            >
              <input
                readOnly
                value={validation.values[fieldName]}
                type="text"
                className="form-control input-lg"
                onClick={() => {
                  setsimple_color1(!simple_color1);
                }}
              />

              <span
                className="input-group-text colorpicker-input-addon"
                onClick={() => {
                  setsimple_color1(!simple_color1);
                }}
              >
                <i
                  style={{
                    height: "16px",
                    width: "16px",
                    background: colorRgb,
                  }}
                />
              </span>
            </div>
          </FormGroup>
          <FormFeedback type="invalid">
            {validation.errors[fieldName]}
          </FormFeedback>
        </Col>
        <Col md={6}>
          {simple_color1 ? (
            <div style={{ position: "absolute", zIndex: "2" }}>
              <ChromePicker
                color={validation.values[fieldName]}
                onChangeComplete={onChangeDo}
                disableAlpha={true}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

export default NibbleInputColor;
