import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

//redux
import { withTranslation } from "react-i18next";
import {
  getClientsSelect,
  getSponsorsAdvertisments,
  deleteSponsorsAdvertisments,
} from "../../../helpers/backend_helper";
import TableContainer2 from "../../../components/Common/TableContainer2";

import "../../../components/Common/custom.css";
import SponsorContractForm from "./SponsorContractForm";
import dayjs from "dayjs";
import SponsorNoticeForm from "./SponsorNoticeForm";

const API_URL = process.env.REACT_APP_TELLIT_API_URL;

const SponsorNotices = (props) => {
  const t = props.t;
  const sponsor = props.sponsor;
  const masterItemName = t("Advertisements");
  const masterItemNamePlural = t("Advertisements");

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
    sponsorId: sponsor.id,
  });
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0,
    search: null,
  });

  const fetchDataClients = async () => {
    const response = await getClientsSelect();
    setClients(response)
  };

  useEffect(() => {
    fetchDataClients();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await getSponsorsAdvertisments({ params: tableOpts });
    setItems(response.data);
    setTableMeta({ ...tableMeta, ...response.meta });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [tableOpts]);

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleItemEditClick(row);
          }}
          title={t("Edit")}
        >
          <i className="fas fa-edit"></i>
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleItemDeleteClick(row);
          }}
          title={t("Delete")}
        >
          <i className="fas fa-times"></i>
        </Button>
      </span>
    );
  }

  const defaultNewItem = {
    id: null,
    contractId: null,
    logoId: null,
    name: null,
    advertising_type: null,
    start_day: null,
    end_day: null,
    active: true,
    entities: [],
    clientId: null,
    sponsorId: sponsor?.id,
    message: '',
    link: '',
    bg_color: '#ffffff',
    font_color: '#000000',
  };

  const columns = [
    {
      dataField: 'logo.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => (<img width="100" src={API_URL + '/' + row.logo?.path}></img>),
      sort: false
    },
    {
      dataField: "contract.name",
      text: t("Contract"),
      formatter: (e, row) => { return t(`${row.contract?.name} - ${row.contract?.advertising_type}`) },
      sort: true,
    },
    {
      dataField: "client.name",
      text: t("Customer"),
      formatter: (e, row) => { return (`${row.contract?.client?.name}`) },
      sort: true,
    },
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
    },
    {
      dataField: "start_day",
      text: t("Period"),
      formatter: (e, row) => {
        return (
          (row.start_day ? dayjs(row.start_day).format("DD/MM/YYYY") : "") +
          " - " +
          (row.end_day ? dayjs(row.end_day).format("DD/MM/YYYY") : "")
        );
      },
      sort: true,
    },
    {
      dataField: "active",
      text: t("Active"),
      formatter: (e, row) => { return (row.active ? <i className="fa fa-check text-success"></i>:<i className="fa fa-times text-danger"></i>) },
      sort: true,
    },
    {
      dataField: "id",
      text: t("Actions"),
      formatter: buttonActions,
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleItemCreateClick = () => {
    setItem(defaultNewItem);
    setIsEdit(false);
    toggle();
  };

  const handleItemEditClick = (row) => {
    setItem({
      ...row,
      start_day: row.start_day ? dayjs(row.start_day).toDate() : null,
      end_day: row.end_day ? dayjs(row.end_day).toDate() : null,
      entities: row.entities?.map( e => { return e.id; })
    });
    setIsEdit(true);
    toggle();
  };

  const handleItemDeleteClick = (row) => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleDeleteItem = async () => {
    const response = await deleteSponsorsAdvertisments(item.id);
    fetchData();
    setDeleteModal(false);
  };

  console.log("tableOpts", tableOpts);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteItem}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardBody>
          <Row>
            <Col xl="12">
              <TableContainer2
                columns={columns}
                data={items}
                sortable={true}
                handleNewItemClick={() => {
                  handleItemCreateClick();
                }}
                addBtnText={t("Add") + " " + masterItemName}
                loading={loading}
                handleSearchBtnClick={(search) => {
                  setTableOpts({ ...tableOpts, search: search });
                }}
                handlePageBtnClick={(page) => {
                  setTableOpts({ ...tableOpts, page: page });
                }}
                pageOptions={{
                  sizePerPage: tableMeta.take,
                  page: tableMeta.page,
                  totalSize: tableMeta.itemCount,
                  custom: true,
                  hideSizePerPage: true,
                }}
                handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
              />

              { item && 
              <Modal isOpen={modal}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit
                    ? t("Edit") + " " + masterItemName
                    : t("Add") + " " + masterItemName}
                </ModalHeader>
                <ModalBody>
                  <SponsorNoticeForm
                    item={item}
                    sponsor={sponsor}
                    clients={clients}
                    onComplete={e=>{ fetchData(); toggle(); }}
                    isEdit={isEdit}
                  ></SponsorNoticeForm>
                </ModalBody>
              </Modal>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorNotices);
