import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../components/Common/Breadcrumb";

// actions
import { withTranslation } from "react-i18next";
import {
  getConfigs,
  setConfigs,
} from "../helpers/backend_helper";
import NibbleInput from "../components/Fields/NibbleInput";


const SysadminConfig = (props) => {
  const t = props.t;
  const masterItemName = t("System configuration");
  //meta title
  document.title = masterItemName + " | Tellit";

  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const fetchData = async () => {
    const item = await getConfigs();
    //const config = item.map((e) => { const name = e.name.toLowerCase(); return {[name]: e.value}} )

    item.forEach((e) => {
      const name = e.name.toLowerCase();
      validation.setFieldValue(name, e.value);
    });

    //console.log("config", config);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      min_phone_digits: item?.min_phone_digits?.value || 8,
      verification_end_user_channel:
        item?.verification_end_user_channel?.value || "none",
      reset_password_channel: item?.reset_password_channel?.value || "email",
      device_change_verify_channel:
        item?.device_change_verify_channel?.value || "email",
    },
    validationSchema: Yup.object({
      min_phone_digits: Yup.number().required(t("required")),
      verification_end_user_channel: Yup.string().required(t("required")),
      reset_password_channel: Yup.string().required(t("required")),
      device_change_verify_channel: Yup.string().required(t("required")),
    }),
    onSubmit: async (values) => {
      console.log("values", values);

      const updateItem = [
        {
          name: "MIN_PHONE_DIGITS",
          value: values.min_phone_digits,
        },
        {
          name: "verification_end_user_channel".toUpperCase(),
          value: values.verification_end_user_channel,
        },
        {
          name: "reset_password_channel".toUpperCase(),
          value: values.reset_password_channel,
        },
        {
          name: "device_change_verify_channel".toUpperCase(),
          value: values.device_change_verify_channel,
        },
      ];

      setLoadingBtn(true);
      const itemResponse = await setConfigs({ configs: updateItem });
      fetchData();
      setLoadingBtn(false);

      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={t("System")} breadcrumbItem={masterItemName} />

          <Card>
            <CardBody>
              {loading && (
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              )}
              {!loading && (
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label">{}</Label>
                    <NibbleInput
                      md={6}
                      label={t("Min phone digits")}
                      fieldName="min_phone_digits"
                      fieldType="number"
                      validation={validation}
                    />

                    <NibbleInput
                      md={6}
                      placeHolder={t("Select")}
                      label={t("Verification of end user with registration and registration changes")}
                      fieldName="verification_end_user_channel"
                      fieldType="select"
                      validation={validation}
                      selectItems={[
                        { name: t("None"), id: "none" },
                        { name: t("By email"), id: "email" },
                        { name: t("By SMS - not implemented yet"), id: "sms" },
                      ]}
                    />

                    <NibbleInput
                      md={6}
                      placeHolder={t("Select")}
                      label={t("Reset password - send new")}
                      fieldName="reset_password_channel"
                      fieldType="select"
                      validation={validation}
                      selectItems={[
                        { name: t("By email"), id: "email" },
                        { name: t("By SMS - not implemented yet"), id: "sms" },
                      ]}
                    />

                    <NibbleInput
                      md={6}
                      placeHolder={t("Select")}
                      label={t("Device change - verify by")}
                      fieldName="device_change_verify_channel"
                      fieldType="select"
                      validation={validation}
                      selectItems={[
                        { name: t("By email"), id: "email" },
                        { name: t("By SMS - not implemented yet"), id: "sms" },
                      ]}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      <i className="fa fa-save"></i> {t("Save")}
                    </Button>
                  </div>
                </Form>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SysadminConfig);
